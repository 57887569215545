import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Subject, takeUntil } from 'rxjs';
import { CanonicalService } from '../../../queries/canonical/canonical.service';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';

@Component({
  selector: 'app-quality_policy',
  templateUrl: './quality-policy.component.html',
  styleUrl: './quality-policy.component.scss'
})
export class QualityPolicyComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    private fetchMetaService: FetchMetaService,
    private titleService: Title,
    private apollo: Apollo,
    private metaService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag()
    if (this.fetchMetaService.getData() == false) {
      let metatags = [];
      this.apollo
        .watchQuery({
          query: META_QUERY,
        })
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          let meta = [];
          meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
            ...metaTag,
          }));
          for (let i = 0; i < meta.length; i++) {
            metatags[meta[i].attributes.URL_ID] = meta[i];
          }
          // console.log("Meta Tags : ", this.metatags);
          this.fetchMetaService.storeData(metatags);
          let metaTagsData = this.fetchMetaService.getData();
          // console.log("Meta Tags ", metaTagsData);
          this.titleService.setTitle(metaTagsData['quality_policy'].attributes.title);
          this.metaService.updateTag({
            name: 'description',
            content: metaTagsData['quality_policy'].attributes.description,
          });
          this.metaService.updateTag({
            name: 'keywords',
            content: metaTagsData['quality_policy'].attributes.keywords,
          });
        });
    } else {
      // console.log("Data Alreday Fetched");
      const data = this.fetchMetaService.getData();
      this.titleService.setTitle(data['quality_policy'].attributes.title);
      this.metaService.updateTag({
        name: 'description',
        content: data['quality_policy'].attributes.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: data['quality_policy'].attributes.keywords,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
