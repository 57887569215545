<div *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee;">
    <div style="margin: auto;">
        <img style="display: block;" class="logo-loading"
            src="https://vcdn.cosgrid.com/website/assets4/navbar/fLogo.svg" alt="Logo loading.." loading="lazy" />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<section class="px-md-5 pt-md-5 pt-3 px-2 position-relative">
    <div class="section-title">
        <h1 class="clr-blue-dark mb-md-5 mb-2 fw-bold h2 text-center">Events</h1>
    </div>

    <div *ngIf="showContent"
        class="openEvent position-fixed p-2 d-flex align-items-center justify-content-center h-100 w-100">
        <div id="paginator"
            class="d-flex my-1 p-1 align-items-center flex-column justify-content-between cardEvents rounded openCard position-relative">
            <button (click)="toogleContent('')" class="close-btn">&times;</button>
            <div class="cardtitle mb-5 mt-1 p-1 text-left d-flex align-items-center flex-column">
                <p class="fw-bold h4 clr-blue-dark text-center">{{ event.attributes.Title }}</p>
                <small class="text-muted">{{ event.attributes.misc }}</small>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <img class="w-50 p-1" [src]="event.image" alt="event" loading="lazy" />
            </div>
            <div class="p-4 pt-0 w-100 text-justify">
                <p class="mt-4 fw-normal text-dark">{{event.attributes.Description}}</p>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-evenly align-items-center flex-wrap">
        <div id="paginator" *ngFor="let event of events"
            class="d-flex my-4 p-1 align-items-center flex-column justify-content-between cardEvents rounded">
            <div class="cardtitle mb-5 mt-1 p-1 text-left d-flex align-items-center flex-column">
                <p class="fw-bold h4 clr-blue-dark text-center">{{ event.attributes.Title }}</p>
                <small class="text-muted">{{ event.attributes.misc }}</small>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <img class="w-70 p-1" [src]="event.image" [alt]="event.attributes.Title" loading="lazy" />
            </div>
            <div class="p-4 pt-0 w-100 text-justify">
                <p class="mt-4 fs-6">{{ event.attributes.Description.split(" ").slice(0, 30).join(" ") + " ..." }}</p>
                <button class="btn readMore" (click)="toogleContent(event)">Read More</button>
            </div>

        </div>
    </div>
</section>