<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h1 class="fw-bold h2 text-center text-md-left">{{main1?.intro?.title}}</h1>
            <p class="fw-bold fs-5">{{main1?.intro?.subtitle}}</p>
            <img [src]="main1?.intro?.logoUrl" class="w-80" alt="Redlex SD-WAN" loading="lazy" />
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0 d-flex justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets-new/sase/sase-cover.png" alt="banner"
                class="animate-bob w-70 align-self-center" loading="lazy" />
        </div>
    </div>
</section>

<!-- overview -->
<section class="px-md-5 my-5 px-2 overview">
    <div class="row p-md-5">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 class="fw-bold clr-white h2">Product Overview</h1>
            <p class="clr-white fs-6" [innerHTML]="main1?.products.desc"></p>
            <a class="btn fs-6 fw-bold btn-primary rounded-pill align-self-center align-self-md-start hover-popup mt-3 mb-5"
                [href]="main1?.products?.whitepaper" target="_blank">Download Whitepaper</a>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center bg-clr-white rounded">
            <img [src]="main1?.products?.img" alt="products Overview" class="w-90" loading="lazy" />
        </div>
    </div>
</section>

<!-- challenges -->
<section class="px-md-5 my-5 px-2">
    <app-problem-list title="Challenges" desc="Network security that may affect your business"
        [data]="main1?.challenges"></app-problem-list>
</section>


<!-- features -->
<section class="px-md-5 my-5 px-2">
    <app-features-list [data]="main1?.how_helps1" [title]="main1?.how_helps_text"
        subtitle="Key Features at a glance"></app-features-list>
</section>

<!-- key components  -->
<section class="px-md-5 my-5 px-2">
    <image-points [data]="key_components_sase_points" title="Key Components of COSGrid SASE"
        image="https://vcdn.cosgrid.com/website/assets-new/sase/sase page.png" leftContentXLCol="5"></image-points>
</section>

<!-- ztna -->
<section class="px-md-5 my-5 px-2">
    <app-problem-list [title]="main1?.ztna_title" [desc]="main1?.ztna_desc" [data]="main1?.ztna"></app-problem-list>
</section>

<!-- sig -->
<section id="sig" class="px-md-5 my-5 px-2">
    <h1 class="fw-bold clr-blue-dark text-center mt-5 h2">
        {{ main1?.sig_title }}
    </h1>
    <p class="mb-4 mt-5 fw-bold text-center h5" [innerHTML]="main1?.sig_desc"></p>
    <div class="row shadow-sm p-1">
        <div class="col-12 col-lg-6">
            <div *ngFor="let item of main1?.sig" class="ms-lg-5">
                <div class="d-flex justify-content-start align-items-center">
                    <img [src]="
                                    'https://vcdn.cosgrid.com/website/assets-new/sase/' + item.icon + '.png'
                                " class="img-fluid" height="35px" width="35px" loading="lazy" [alt]="item.icon" />
                    <p class="pt-2 px-2 fw-bold fs-6">
                        {{ item?.desc }}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <img [src]="main1?.sig_img" class="w-50" loading="lazy" alt="sig image" />
        </div>
    </div>
</section>

<section class="px-md-5 my-5 px-2" id="FWaas">
    <h1 class="h2 fw-bold clr-blue-dark">Cloud Firewall - Firewall As a Service (FWaas) </h1>
    <p class="fs-6">As Businesses adopt cloud infrastructure services like AWS to enhance scalability, they must ensure
        that enterprise firewall functionalities are maintained throughout the organization for all users and locations.
        Cloud Firewall- FWaaS enables organizations to create secure local breakouts for all applications without the
        need to purchase, deploy, or oversee security appliances. Security features, such as a comprehensive Layer 7
        firewall, are provided through a cloud service that can dynamically adjust to accommodate SSL inspection,
        increasing bandwidth and user requirements, as well as cloud application traffic with persistent connections.
    </p>
    <div class="bg-white row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 px-90">
        <div class="col-md-3 col-12 image-box d-flex justify-content-center align-items-center hover-popup"
            *ngFor="let item of main1?.fwaas">
            <div class="d-flex flex-column justify-content-center align-items-center w-90 h-90 p-3 image-anchor">
                <img [src]="item.icon" alt="Firewall As a service" loading="lazy" class="w-30 mb-1">
                <p class="h-20 text-center" style="max-height: 80px; object-fit: contain;">{{item.desc}}</p>
            </div>
        </div>
    </div>
</section>

<!-- sd-wan -->
<section class="px-md-5 my-5 px-2">
    <app-features-list [title]="main1?.sdwan_text" subtitle="Key Features at a glance"
        [data]="main1?.sdwan"></app-features-list>
</section>

<!-- ReFlex appliance -->
<section class="px-md-5 my-5 px-2">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <p class="mb-5 fw-bold fs-6">
            {{ main1?.sdwan_midtext }}
        </p>
        <div class="d-flex justify-content-center align-items-center">
            <img [src]="main1?.sdwan_img" class="w-75" loading="lazy" alt="sdwan image" />
        </div>
    </div>
</section>

<!-- advantages -->
<section class="px-md-5 my-5 px-2">
    <h1 class="text-center fw-bold clr-blue-dark mb-3 h2">Advantages - Z3 SASE</h1>
    <carousel-list [data]="sase_advantages_component" class="p-1"
        baseUrl="https://vcdn.cosgrid.com/website/assets-new/sase/"></carousel-list>
</section>

<!-- benifits -->
<section class="px-md-5 my-5 px-2">
    <image-points [data]="sase_benefits_points" [title]="main1?.benefits_text" [subtitle]="main1?.benefits_subtext"
        [image]="main1?.benefits_img" leftContentXLCol="7" colorStyle="2"></image-points>
</section>

<!-- deployments options and contact us -->
<section class="px-md-5 my-5 px-2">
    <h1 class="mt-3 fw-bold clr-blue-dark text-center h2">
        {{ main1?.deployment_text }}
    </h1>
    <p class="fw-bold mt-2 text-center h5">
        {{ main1?.deployment_subtext }}
    </p>
    <div class="d-flex justify-content-center">
        <img [src]="main1?.deployment_img" alt="benefits" class="w-50" loading="lazy" />
    </div>
    <div class="" style="background-color: #ededed !important; margin-top: 3em">
        <div id="intro" class="container-fluid">
            <div class="row pt-2 pb-4 d-flex justify-content-center ">
                <div class="col-md-6 col-12 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="text-center text-md-left fw-bold h2">Interested in this product?</h1>
                    <p class="fs-6">Click the button below & submit your details - we will call you back!</p>
                    <a class="btn btn-primary" routerLink="/company/contacts">Contact
                        Us</a>
                </div>
            </div>
        </div>
    </div>
</section>