import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { Title, Meta } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-sase',
    templateUrl: './sase.component.html',
    styleUrls: ['./sase.component.scss'],
})
export class SaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private destroy$ = new Subject<void>();
    paddingTop: string = '100px';

    sase_advantages_component: any = [
        {
            id: 1,
            title: '3X Improved Threat Detection Multi- pronged Approach',
            imgURL: 'detection (3)',
            points: [
                'ML based Anomaly Detection',
                'Encrypted Traffic Analytics',
                'MITRE Attack Threat hunting',
            ],
            width: '75%',
        },
        {
            id: 2,
            title: '4x Improved App/Workload/ User Protection',
            imgURL: 'big-data (2)',
            points: ['Micro-segmentation & Defense in depth', 'Zero Trust Access', 'User Entity Behaviour Analysis'],
        },
        {
            id: 3,
            title: 'Faster Threat Detection and Real-time Response',
            imgURL: 'data-security',

            points: ['Streaming Big-Data analytics and alerts', 'SDN Firewall', 'Hybrid Mesh Firewall'],
        },
        {
            id: 4,
            title: 'Comprehensive Visibility & Insights',
            imgURL: 'dashboard (4)',
            points: [
                'Deep Packet Inspection  & Protocol Analysis',
                'API Access insight and Compliance ready',
                'Digital Experience Monitoring',
            ],
        },
        {
            id: 5,
            imgURL: 'router (2)',
            title: 'Seamless & Reliable Secure Channel',
            points: [
                'Patented Multi-path Link Bonding',
                'Best in class Multi-radio 5G SD-WAN',
                'Fine-grained App Forward Policy',
            ],
        },
        {
            id: 6,
            title: 'Superior Security Orchestration & Converged Security',
            imgURL: 'cyber-security (5)',

            points: ['Easy Ops', 'Superior TCO', 'Open API and flexible platform'],
        },
    ];
    sase_benefits_points: any = [
        {
            title: 'Defend against known and unknown threats with<span class="clr-blue-dark fw-bold"> Advanced Threat Detection</span>(NDR) / UEBA',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Protect data leakage</span> in your SaaS Apps along with Saas Acceleration',
        },
        {
            title: 'Enable a <span class="clr-blue-dark fw-bold">Secure remote workforce</span> and Enforce an Acceptable Use Policy (AUP)',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Visibility & Reporting</span> along with Digital Experience Management (DEM)',
        },
        {
            title: 'Reduce administrative complexity and <span class="clr-blue-dark fw-bold"> cost effectiveness</span>',
        },
        {
            title: 'Open API & <span class="clr-blue-dark fw-bold">Multi-vendor Data integration</span> support',
        },
    ];
    key_components_sase_points: any = [
        {
            title: '<span class="clr-blue-dark fw-bold">Software Defined WAN</span>',
            desc: 'ReFleX SD-WAN',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Zero Trust Network Access</span>',
            desc: 'MicroZAccess',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Cloud Security Access Broker</span>',
            desc: 'CASB',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Secure Internet /web gateway</span>',
            desc: 'SWG',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Firewall / Cloud Firewall</span>',
            desc: '(Fwas)',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Web Application Firewall</span>',
            desc: 'WAF',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Advanced Threat Protection</span>',
            desc: 'NetShield NDR',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Unified Management / Digital Experience</span>',
            desc: 'DEM'
        },
    ];
    main1: any = {
        id: '',
        intro: {
            imgUrl: 'https://vcdn.cosgrid.com/website/assets-new/sase/1.webp',
            title: 'Transforming Secure Access Connectivity',
            subtitle: '',
            logoUrl: 'https://vcdn.cosgrid.com/website/assets-new/sase/2.webp',
            imgHeight: '',
            imgWidth: '',
            logoWidth: '',
            logoHeight: '',
        },
        products: {
            desc: "COSGrid Z3 SASE is a comprehensive network and security solution that combines the principles of <span> Secure Access Service Edge (SASE) </span> with COSGrid's innovative technologies such as: <span> MicroZAccess </span>(ZTNA), <span> Secure Gateway</span> (SIG), <span> RefleX SD-WAN </span> (SD-WAN). It provides enhanced security, zero-trust connectivity, seamless deployment, and simplified management. With advanced threat protection, zero-touch provisioning, and a flexible architecture, COSGrid Z3 SASE ensures robust network performance, centralized security policies, and future readiness. It leverages 5G wireless and existing wired networks to create a secure and reliable connectivity experience, enabling organizations to protect their distributed sites and remote employees efficiently.",
            whitepaper: 'https://vcdn.cosgrid.com/website/strapi/Brochure_COS_Grid_SASE_V1_93daea1918.pdf',
            img: 'https://vcdn.cosgrid.com/website/assets-new/sase/sase-overview.png',
        },
        challenges: {
            imageRoute: 'https://vcdn.cosgrid.com/website/assets-new/sase/',
            data: [
                {
                    image: 'zero (1)',
                    title: '',
                    desc: 'Increasing Sophisticated Zero day attacks',
                },
                {
                    image: 'insider (1)',
                    title: '',
                    desc: 'Insider & Supply chain Attacks',
                },
                {
                    image: 'time consuming',
                    title: '',
                    desc: 'Expensive & time consuming - Compliances & Governance Challenge',
                },

                {
                    image: 'detection (2)',
                    title: '',
                    desc: 'Poor Threat Detection with too many alerts',
                },
                {
                    image: 'complexity (1)',
                    title: '',
                    desc: 'Too much Complexity in implementing security policies',
                },
                {
                    image: 'web-visibility',
                    title: '',
                    desc: 'Poor Network Visibility and User experience',
                },
            ]
        },
        how_helps_text: 'How COSGrid SASE helps?',
        how_helps1: {
            imageRoute: 'https://vcdn.cosgrid.com/website/assets-new/sase/',
            data: [
                {
                    image: 'cloud first',
                    title: 'A cloud-first architecture',
                    desc: '',
                },
                {
                    image: 'seamless security',
                    title: 'Seamless security experience',
                    desc: '',
                },
                {
                    image: 'zero attack surface',
                    title: 'zero Attack surface',
                    desc: '',
                },
                {
                    image: 'zero trust access how sase helps',
                    title: 'Zero Trust Network Access',
                    desc: '',
                },
            ]
        },
        how_helps: [
            {
                icon: 'cloud first',
                title: 'A cloud-first architecture',
            },
            {
                icon: 'seamless security',
                title: 'Seamless security experience',
            },
            {
                icon: 'zero attack surface',
                title: 'Zero Attack surface',
            },
            {
                icon: 'zero trust access how sase helps',
                title: 'Zero Trust Network Access',
            },
        ],
        ztna_title: 'COSGrid MicroZAccess (ZTNA)',
        ztna_subtitle: 'Zero Trust Network Access',
        ztna_desc:
            '<b>MicroZAccess is a Smart Zero Trust Network Access (ZTNA)</b> client in Desktop which securely authenticates the user and seamlessly connects the device to the Cloud through reliable, high performance and encrypted tunnels.',
        ztna: {
            imageRoute: 'https://vcdn.cosgrid.com/website/assets-new/sase/',
            data: [
                {
                    image: 'peer-to-peer',
                    desc: 'Peer to Peer Overlay model for improved privacy and performance',
                },
                {
                    image: 'flexibility',
                    desc: 'Flexible Deployment - Host/Workload Agent & Gateway approach',
                },
                {
                    image: 'integration',
                    desc: 'Integrated Device Trust and Superior Identity MFA based Access',
                },
                {
                    image: 'simple deploy',
                    desc: 'Super Simple to Deploy and Manage<br/><br/>',
                },
                {
                    image: 'internet-security',
                    desc: 'Platform approach for Comprehensive Security - Support in SD-WAN and SASE',
                },
                {
                    image: 'compliant',
                    desc: 'Stateful device compliance checks before, and during, a connection Granular policy enforcement',
                },
            ],
        },
        sig_title: 'COSGrid SecureGateway (SIG)',
        sig_subtitle: 'Secure Internet Gateway',
        sig_desc:
            '<span class="clr-blue-dark fw-bold"> SecureGateway is a Secure Internet Gateway (SIG) </span> solution that provides Firewall as a Service (FWaaS). It offers URL and content-based policies, granular app controls, and advanced visibility and reporting features.',
        sig: [
            {
                icon: 'office (1)',
                desc: 'Selective bypass of Microsoft 365 traffic by better experience',
            },
            {
                icon: 'shield',
                desc: 'Full or selective SSL decryption',
            },
            {
                icon: 'granular control',
                desc: 'App visibility and granular controls',
            },
            { icon: 'search', desc: 'URLs Accessed reporting' },
            {
                icon: 'view',
                desc: 'Granular App controls such as blocking uploads, e-mail attachments',
            },
            {
                icon: 'automation',
                desc: 'Automated User Provisioning via SAML and AD sync',
            },
            {
                icon: 'https',
                desc: 'HTTPS Inspection with Decryption & w/o Decryption',
            },
            {
                icon: 'decryption',
                desc: 'Selective Proxying & Selective Decryption',
            },
            {
                icon: 'url',
                desc: 'URL Category and content based allow/deny policies',
            },
        ],
        sig_img: 'https://vcdn.cosgrid.com/website/assets-new/sase/SASE SWG CG.png',
        sdwan_text: 'COSGrid ReFleX SD-WAN',
        sdwan_subtext: 'Software Defined WAN',
        sdwan_desc:
            "COSGrid's RefleX-WAN is a Cloud Managed Software Defined platform designed to transform WAN into a cloud-centric world. It is built to deliver the simplicity, flexibility and cost-effective WANs for any branch office locations and scale also seamlessly run and integrate with DC locations and Clouds deployed. ",
        sdwan_midtext:
            'Branch routers of the past are replaced by ReFleX SD-WAN. They offer zero-touch configuration, centralised policy and management of appliances, virtual private network (VPN), and dynamic path selection based on business or application policy.',
        sdwan_img: 'https://vcdn.cosgrid.com/website/assets/sdwan/sdwan_solution.png',
        sdwan: {
            imageRoute: 'https://vcdn.cosgrid.com/website/assets-new/sase/',
            data: [
                {
                    image: 'acceleration',
                    title: 'Unified and Accelerated Access',
                    desc: 'Resilient & secure access, Scalable security for infrastructure, User experience visibility',
                },
                {
                    image: 'protection',
                    title: 'Zero Trust platform',
                    desc: 'Software designed parameters, Distributed SDN/NFV, Micro-segmentation',
                },
                {
                    image: 'process',
                    title: 'Adaptive orchestration & management',
                    desc: 'Deep network visibility, intelligent threat detection and mitigation',
                },
            ],
        },
        benefits_text: 'Benefits of COSGrid SASE',
        benefits_subtext: 'That helps your organisation worry less about network security',
        benefits_img: 'https://vcdn.cosgrid.com/website/assets-new/sase/33.webp',
        deployment_text: 'Deployment Options',
        deployment_subtext: 'Without affecting the current network security system of your organisation',
        deployment_img: 'https://vcdn.cosgrid.com/website/assets-new/sase/34.webp',
        fwaas: [
            {
                desc: '1st level of Protection for users & endpoint connecting to Internet from anywhere',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/globe-grid.png'
            },
            {
                desc: 'Distributed Edge across geographies closer to the users & branch offices',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/manager.png'
            },
            {
                desc: 'Centrally manages IP, port, protocol and application rules (layer 3, 4 and 7)',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/chain.png'
            },
            {
                desc: 'Forwards web traffic (ports 80 & 443) to secure web gateway',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/web-traffic.png'
            },
            {
                desc: 'Automated tunnels from SD-WAN or VPN/ZTNA in the end point ',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/endpoint.png'
            },
            {
                desc: 'Deep Packet Inspection (DPI) on selective traffic/destination domains',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/statistics.png'
            },
            {
                desc: 'Block high risk applications and protocols (layer 7 application visibility & control)',
                icon: 'https://vcdn.cosgrid.com/website/assets-new/sase/protocols.png'
            }
        ]
    };
    constructor(
        private route: ActivatedRoute,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
        private titleService: Title,
        private metaService: Meta,
        private canonicalService: CanonicalService
    ) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                        ...metaTag,
                    }));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['sase'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData['sase'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['sase'].attributes.keywords,
                    });
                });
        } else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['sase'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data['sase'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['sase'].attributes.keywords,
            });
        }

        this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
            if (fragment) {
                setTimeout(() => {
                    // console.log('Fragment : ', fragment);
                    const element = document.getElementById(fragment);
                    if (element) {
                        element.scrollIntoView();
                        this.paddingTop = '200px';
                    } else {
                        this.paddingTop = '100px';
                    }
                }, 200);
            }
        });
    }
    ngAfterViewInit(): void {
        this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
            if (fragment) {
                setTimeout(() => {
                    // console.log('Fragment : ', fragment);
                    const element = document.getElementById(fragment);
                    if (element) {
                        // console.log("element present");

                        element.scrollIntoView();
                        this.paddingTop = '200px';
                    } else {
                        this.paddingTop = '100px';
                    }
                }, 100);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
