import { IUsecasesList } from '../../../../core/models/usecases.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteConstants } from '../../../../config/route.config';
import { USECASES } from '../../../../data/usecases';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-usecases',
    templateUrl: './usecases.component.html',
    styleUrls: ['./usecases.component.scss'],
})
export class UsecasesComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    usecasesList: IUsecasesList[] = USECASES
    marginTop: any = '70px';
    approach = [
        {
            title: 'Networking, Security & Cloud',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/sd-wan-usecases/Networking,%20Security%20&%20Cloud.svg',
            text: 'Integrated, optimized and an effective solution at the intersection',
            border: '2px solid #003F88',
        },
        {
            title: 'High Performant Resilient WAN',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/sd-wan-usecases/High%20Performant%20Resilient%20WAN.svg',
            text: 'Best-in-class patented technology that leverages multiple WAN links, access types',
            border: '2px solid #003F88'
        },
        {
            title: 'Network-as-a-Service',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/sd-wan-usecases/Network-as-a-Service.svg',
            text: 'Aligned with your enterprise business model and enabled by Software defined approach',
            border: '2px solid #003F88'
        },
        {
            title: 'Extendable Platform',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/sd-wan-usecases/Extendable%20Platform.svg',
            text: 'Standards based with open APIs and hence no vendor lock-in. Easy to integrate',
            border: ''
        },
    ];

    constructor(private canonicalService: CanonicalService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo, private activatedRoute: ActivatedRoute,) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any)?.metaTags?.data?.map((metaTag: any) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData?.['sd-wan-usecases']?.attributes?.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['sd-wan-usecases']?.attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['sd-wan-usecases']?.attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['sd-wan-usecases']?.attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['sd-wan-usecases']?.attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['sd-wan-usecases']?.attributes.keywords
            });

        }

        this.activatedRoute.fragment.subscribe(fragment => {
            if (fragment) {
                setTimeout(() => {
                    const element = document.getElementById(fragment);
                    if (element) {
                        element.scrollIntoView();
                        this.marginTop = '200px';
                    }
                    else {
                        this.marginTop = '70px';
                    }
                }, 1000);
            }
        });

    }
    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
