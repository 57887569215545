import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import { RouteConstants } from '../../../../config/route.config';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';


@Component({
    selector: 'app-press-release',
    templateUrl: './press-release.component.html',
    styleUrls: ['./press-release.component.scss']
})
export class PressReleaseComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
        private canonicalService: CanonicalService

    ) { }

    bannerBackground = "https://vcdn.cosgrid.com/website/assets-new/press-release/press-release.jpg"
    productRelease = `/resources/news-${RouteConstants.PRESS_RELEASE}/ndr-product-launch`
    dubaiLink = `/resources/news-${RouteConstants.PRESS_RELEASE}/dubai-middle-east-sase`

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                            {
                                ...metaTag,
                            }
                        ));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData['press-release']?.attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                metaTagsData['press-release']?.attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['press-release']?.attributes.keywords
                        });
                    });
            }
            else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['press-release']?.attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content:
                        data['press-release']?.attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['press-release']?.attributes.keywords
                });

            }
        }
        catch {
            //     this.titleService.setTitle('Contact Us');
            // this.metaService.updateTag({ name: 'description', content: 'Contact to discuss your requirements. Connect with our team via phone, email, or in-person. ' });
            // this.metaService.updateTag({ name: 'keywords', content: 'contact us, get in touch, requirements, secure software-defined networking, SD-WAN, SASE, NDR, business continuity, threat detection, reduced costs, NetSecOps, phone, email, offices' });
        }

        // this.klentyService.load();  //loads the klenty scriptw
    }

    content: Array<string> = [
        'COSGrid, a leading SASE company is excited to unveil <b>NetShield NDR</b> – an <b>adaptive</b> and <b>holistic AI/ML based advanced threat detection</b> and <b>response platform</b> at <b>GISEC Global 2024</b>, Dubai in august presence of CISO’s and Global Cybersecurity thought leaders.',
        'NetShield - Network Detection and Response (NDR) platform provides superior threat detection and lower false alerts through advanced and multi-dimensional traffic inspection, threat detection techniques, Device based discovery and classification, context based anomaly detection and adaptive response.',
        'Cybersecurity threats are evolving and encompass a wide range of risks, including ransomware, zero-day exploits, APTs, and insider threats. Swift detection and response to these threats are crucial for maintaining the security of an organization. Effective threat detection necessitates a thorough understanding of network activities and continuous monitoring of network events. Network Detection and Response solutions play a vital role in expediting investigations, containment, and remediation of incidents. Moreover, the utilization of machine learning (ML) algorithms is essential in enhancing threat detection accuracy, minimizing false alarms, and enabling prompt responses.The rise in IoT devices and the BYOD trend has introduced new vulnerabilities in securing network endpoints. Furthermore, organizations are transitioning towards a zero-trust model, where trust is not assumed by default. However, without comprehensive visibility into network activities, defining stringent zero-trust policies can be arduous for network administrators. An NDR solution equipped with user-friendly visibility features can greatly simplify the implementation of robust zero-trust policies.',
        '"We\'re thrilled to unveil NetShield NDR at GISEC Global, the premier cybersecurity event in the region. NetShield NDR is a game changer, enabling organizations to simplify cybersecurity operations, gain full visibility into network and cloud activity. Through NetShield NDR, organizations gain <b>enhanced visibility</b> into their network with <b>3X higher accuracy</b> in detection through a <b>multi-pronged approach</b>, enabling proactive response to threats and minimizing the impact of breaches along with Superior probes collection from threat detection to threat remediation with ease of Management" said <b>Murugavel Muthu, Founder & CEO of COSGrid Networks.</b>',

    ]

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }

}
