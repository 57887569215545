<section class="px-md-5 px-2 my-5 pt-3"
    [ngStyle]="{'background': 'url(' + backgroundBanner + ') no-repeat center 20% /cover'}"
    style=" height: 65vh; padding-left: 5%;">
    <div class="row h-100">
        <div class="h-100 col-12 d-flex justify-content-center align-items-center h-100 bg-transparent">
            <div class="custom-width text-center p-1 p-md-4 rounded-lg shadow-lg glass-morphism">
                <p class="fw-bold display-4 text-uppercase">Partners</p>
            </div>
        </div>
    </div>
</section>


<!-- <section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 class="fw-bold h2 text-center text-md-left">Partners</h1>
            <p class="pt-3 text-start fs-5">Become a partner and grow with</p>
            <img src="https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg" class="pt-3 logo" alt="partner image" />
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets/images/partners/partners-banners.png" class="w-80" loading="lazy" alt="partner"/>
        </div>
    </div>
</section> -->

<!-- partner program -->
<section class="px-md-5 my-5 px-2">
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <img class="w-70" src="https://vcdn.cosgrid.com/website/assets-new/partner/partner.png"
                alt="become a Partner" loading="lazy" />
        </div>
        <div class="col-12 col-md-6">
            <h1 class="fw-bold h2 mb-3">{{ partnersProgram.title }}</h1>
            <p *ngFor="let i of partnersProgram.text" class="text-justify fs-6" [innerHTML]="i"></p>
        </div>
    </div>
</section>

<!-- why cosgrid -->
<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6">
            <h1 class="fw-bold h2 mb-3">{{ whyCOSGrid.title }}</h1>
            <p *ngFor="let i of whyCOSGrid.text" class="text-justify fs-6" [innerHTML]="i"></p>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <div class="">
                <img class="w-100" src="https://vcdn.cosgrid.com/website/assets-new/partner/aboutus-why.svg"
                    alt="become a Partner" loading="lazy" />
            </div>
        </div>
    </div>
</section>

<!-- our partners -->
<section class="px-md-5 my-5 px-2">
    <h1 class="fw-bold text-center h2">Our partners</h1>
    <div class="row mt-5">
        <div class="col-6 col-md-3 my-2 d-flex align-items-center justify-content-center mb-4"
            *ngFor="let item of ourPartners">
            <img [src]="item.imgURL" [alt]="item.title" [class]="item.class" loading="lazy" />
        </div>
    </div>
</section>

<!-- industry partners -->
<section class="px-md-5 my-5 px-2" id="industry_partners">
    <h1 class="fw-bold text-center h2">Industry partners</h1>
    <div class="row mt-5">
        <div class="col-12 col-md-4 my-2 d-flex align-items-center justify-content-center mb-4"
            *ngFor="let item of industry_partners">
            <img [src]="item.imgURL" [alt]="item.title" [class]="item.class" loading="lazy" />
        </div>
    </div>

</section>

<!-- become a partner -->
<section class="px-md-5 my-5 px-2 becomePartner" id="becomePartner">
    <div class="container pt-5 pb-5 text-center partner-box">
        <div class="mt-4">
            <h1 class="fw-bold h2">{{ becomePartner.title }}</h1>
        </div>
        <div class="mt-4">
            <p *ngFor="let i of becomePartner.text" [innerHTML]="i" class="fs-6"></p>
        </div>
        <div class="mt-5">
            <a href="https://partner.cosgrid.com"
                class="btn fw-semibold  btn-primary text-capitalize rounded-5 pl-4 pr-4">
                Join us
            </a>
        </div>
    </div>
</section>