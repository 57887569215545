import { Component } from '@angular/core';

@Component({
  selector: 'app-sig',
  templateUrl: './sig.component.html',
  styleUrl: './sig.component.scss'
})
export class SigComponent {
  intro = {
    logo: 'https://vcdn.cosgrid.com/website/assets-new/sig/sig-logo.webp',
    alt: 'SIG',
    description: 'Secure your organization with advanced web filtering with COSGrid Secure internet access / Secure Gateway SIG, blocks harmful sites, manages cyber risks, and enforces compliance with customizable policies for enhanced productivity, data protection, and safer browsing experiences tailored to your needs.',
    buttons: [
      {
        text: 'Sign up and Try now',
        href: 'https://cosgrid.net/auth/sign-up',
        target: '_blank',
        external: true
      },
      {
        text: 'Request a demo',
        href: '/company/contacts',
        target: '_self',
        external: false
      },
      // {
      //   text: 'Download Solution brief',
      //   href: '/support',
      //   target: '_self',
      //   external: false
      // }
    ],
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/sig-home.png',
  }

  about = {
    title: 'Secure Internet Access with Smart web filtering',
    description: 'Establish custom rules for specific users or groups to manage website by blocking/allowing the access . This grants administrators full control over Internet access, enabling users / employees to browse securely and avoid online threats',
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/webfilterimage.webp',
    alt: 'Work Flow'
  }

  features = {
    title: 'Simplified & Secure Internet Access',
    description: 'Ensures secure, seamless access to the internet while protecting against online threats, ensuring compliance, and optimizing web traffic performance',
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/url-filter.webp',
    alt: 'UI Sample',
    points: [
      '<span class="clr-blue-dark fw-bold">Real-Time Threat Protection:</span> Detects and blocks malicious content before it reaches users.',
      '<span class="clr-blue-dark fw-bold">Web Filtering & Categorization:</span> Granular control over web access, enforcing business policies and ensuring compliance.',
      '<span class="clr-blue-dark fw-bold">SSL Inspection:</span> Inspects encrypted traffic to prevent threats hidden in HTTPS connections.',
      '<span class="clr-blue-dark fw-bold">Advanced URL and Content Analysis:</span> Protects against phishing, malware, and other web-based attacks.',
      '<span class="clr-blue-dark fw-bold">Cloud & On-Premise Deployment:</span> Flexible architecture to meet the needs of modern organizations.',
      '<span class="clr-blue-dark fw-bold">Granular User & Device Policies:</span> Enforces security and compliance at the user or device level.'
    ]
  }

  howItsWork = {
    title: 'Protect against web based Threats with Secure Internet Access',
    description: 'Allow, block, or isolate user access to specified web categories , URL profile (blacklist, whitelist, category reputation), IP, Applications or domains to stop web-based threats and ensure compliance with organizational policies.',
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/add-webfilter.webp'
  }

  applicationFiltering = {
    title: 'Application Filtering & Bandwidth Management',
    description: 'Allows organizations to control internet access based on specific applications rather than just website URLs. It helps manage and restrict access to cloud apps, social media, streaming services, and other web-based tools, enhancing security and productivity. This approach ensures better bandwidth management, data protection.',
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/applications-filter.webp'
  }

  tracking = {
    title: 'Track Web Activity Visibility and Reporting',
    description: 'Enhance the security of your network by gaining comprehensive insights into users online activities. Monitor which users have attempted to access restricted or flagged websites, and utilize filtering options in reports to focus on particular users, websites, Applications or categories of web content.',
    image: 'https://vcdn.cosgrid.com/website/assets-new/sig/logs-dashboard.webp'
  }

  advantages = [
    {
      image: 'https://vcdn.cosgrid.com/website/assets/sdwan/Use%20cases/remote%20access.png',
      title: 'Secure Remote Access and Data Prevention',
      points: [
        'Enforces security policies for all devices, even remote users',
        'Ensures safe browsing',
        'Blocks access to unauthorized data-sharing sites.',
        'Monitors and controls outbound traffic to prevent breaches.',
      ]
    },
    {
      image: 'https://vcdn.cosgrid.com/website/assets-new/sig/cloud%20workload.png',
      title: 'Enhanced Security and Productivity',
      points: [
        'Blocks malicious websites, phishing attempts, and malware.',
        'Prevents cyber threats before they reach the network.',
        'Enhances productivity by restricting non-work websites',
        'Helps businesses enforce internet usage policies.',
      ]
    },
    {
      image: 'https://vcdn.cosgrid.com/website/assets-new/sig/flexibility.png',
      title: 'Bandwidth Optimization and Customizable Filtering',
      points: [
        'Reduces congestion by blocking high-bandwidth sites',
        'Ensures business-critical applications run smoothly.',
        'Allows organizations to set rules based on categories, or custom lists.',
        'Helps workplaces enforce appropriate web usage.',
      ]
    },
  ]
}
