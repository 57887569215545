

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { take, takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../../../config/route.config';
import { CareersService } from '../../../../../queries/carrers/careers.service';
import { FetchMetaService } from '../../../../../queries/Meta/fetchmeta.service';
import { environment } from '../../../../../../environments/environment';
import POSITION_IDS_QUERY from '../../../../../queries/carrers/position_id.query';
import META_QUERY from '../../../../../queries/Meta/meta.service';
import POSITION_DETAILS_QUERY from '../../../../../queries/carrers/postion_details.query';
import { CanonicalService } from '../../../../../queries/canonical/canonical.service';
import { ReCaptcha2Component } from 'ngx-captcha';



@Component({
    selector: 'app-position',
    templateUrl: './position.component.html',
    styleUrls: ['./position.component.scss'],
})
export class PositionComponent implements OnInit, OnDestroy {
    ERROR_LINK = RouteConstants.ERROR;
    private destroy$ = new Subject<void>();
    opening_positions_ids: string[] = [];
    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component | undefined;
    opening_positions_details: any = {};
    loading!: boolean;
    errors: any;
    selectedFile: any;
    onSubmitted = false;
    input = 'data';
    filename: string = '';
    file: any;
    opening_positions_urls: any;
    public captchaIsLoaded = false;
    public captchaSuccess = false;
    public captchaIsExpired = false;
    public captchaResponse?: string;
    public theme: 'light' | 'dark' = 'light';
    public size: 'compact' | 'normal' = 'normal';
    public lang = 'en';
    public type!: 'image' | 'audio';
    input_id: any;
    can_be_uploaded: any;
    metaContent = {
        'backend-developer': {
            title: 'Backend Developer Job in Chennai | COSGrid Careers',
            desc: 'Join our Chennai-based Enterprise Networking startup as a Full-Time Backend Developer. Build innovative web applications and Work with cutting-edge technologies and solve critical customer problems.',
            key: 'Backend Developer, Engineering, Chennai, India, Full-Time, Python, Django, RESTful APIs, Linux, git, algorithms, data structures, computational complexity, cryptography, HTML, CSS, Redis, Golang, Lua, application submission',
        },
        'embedded-sw-developer': {
            title: 'Embedded Software Developer Job in Chennai | COSGrid Careers',
            desc: 'Join our Chennai-based Enterprise Networking startup as a Full-Time Embedded Software Developer. Utilize your networking and embedded systems skills to design and implement software for cutting-edge devices. ',
            key: 'Embedded Software Developer, Networking, Engineering, Chennai, India, Full-Time, embedded systems, software development, system software, efficiency, stability, scalability, product design, software QA, I/O performance, hardware interface, third-party software, open source, application submission',
        },
        'frontend-developer': {
            title: 'Frontend Developer Job in Chennai | COSGrid Careers',
            desc: 'Join our Chennai-based Enterprise Networking startup as a Full-Time Frontend Developer. Work on cutting-edge technologies in the cloud and IoT-centric world, supported by comprehensive research and IP development.',
            key: 'Frontend Developer, Engineering, Chennai, India, Full-Time, CSS3, Javascript, HTML5, JSON, Angular, CSS preprocessors, responsive web design, RESTful web services, APIs, Webhooks, system architecture, large-scale web application, application submission',
        },
        'sales-executive': {
            title: 'Senior Sales Executive Job in Chennai | COSGrid Careers',
            desc: 'Join our Enterprise Networking startup as a Full-Time Senior Sales Executive in Bangalore, Chennai, or Mumbai. Work with cutting-edge technologies in the cloud and IoT-centric world, supported by comprehensive research and IP development.',
            key: 'Senior Sales Executive, Sales, Bangalore, Chennai, Mumbai, Full-Time, IT, Cybersecurity, sales experience, sales opportunities, inbound calls, outbound calls, account management, sales revenue targets, sales activities, market research, customer preferences, client relationships, contract negotiations, data analysis, application submission',
        },
    };
    applicationForm = new FormGroup({
        fullname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(15)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [
            Validators.required,
            Validators.pattern('(?:(?:\\+|0{0,2})91(\\s*[\\- ]\\s*)?|[0 ]?)?[789]\\d{9}|(\\d[ -]?){10}\\d'),
        ]),
        recaptcha: new FormControl('', [Validators.required])
        // msg: new FormControl(''),
    });

    constructor(
        private http: HttpClient,
        private apollo: Apollo,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private careersService: CareersService,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private canonicalService: CanonicalService
    ) {

    }

    onSubmit = async () => {
        this.onSubmitted = true;
        this.loading = true;
        let formData = new FormData();
        formData.forEach((value, key) => {
            // console.log(key, value);
        });
        console.log(this.applicationForm.value)
        if (this.applicationForm.valid && this.file != null) {
            let submitData = new FormData();
            let innerData = {
                data: {
                    Fullname: this.applicationForm.get('fullname')!.value,
                    Email: this.applicationForm.get('email')!.value,
                    Phone: this.applicationForm.get('phone')!.value,
                    Position: this.opening_positions_details.Title,
                    id: 0,
                },
            };
            // innerData['Fullname']=this.applicationForm.get('fullname').value;
            // innerData['Email']=
            // innerData['Phone']=this.applicationForm.get('phone').value;
            // innerData['Position']=this.opening_positions_details.Title;

            // submitData.append('files.Resume', this.file, this.file.name)

            // console.log(this.file);
            // console.log(innerData);
            // this.apollo
            //     .mutate({
            //         mutation: APPLICATION_MUTATION,
            //         variables: {
            //             fullname: data.fullname,
            //             email: data.email,
            //             phone: data.phone,
            //             position: this.opening_positions_details.title,
            //         },
            //     })
            this.captchaElem?.reloadCaptcha();
            console.log("last")
            return
            this.http.post(`${environment.apiUrl}/api/applications`, innerData).pipe(takeUntil(this.destroy$)).subscribe(
                (data: any) => {
                    // console.log("Data ", data );
                    // const id = data?.createApplication?.application?.id;

                    const id = data?.id;
                    const file = new File([this.file], this.file?.name.replaceAll(' ', '_'), {
                        type: this.file.type,
                        lastModified: this.file.lastModified,
                    });
                    this.careersService.uploadResume(file, id).pipe(takeUntil(this.destroy$)).subscribe((res) => {
                        this.http.get(`${environment.apiUrl}/api/applications/${id}`).subscribe((res: any) => {
                            // console.log(res);
                            let data = {
                                data: {
                                    Email: res.data.attributes.Email,
                                    Fullname: res.data.attributes.Fullname,
                                    Position: res.data.attributes.Position,
                                    Phone: res.data.attributes.Phone,
                                    id: res.data.id,
                                },
                            };
                            // console.log(data);
                            this.http.post(`${environment.apiUrl}/api/applications/`, data).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
                                // console.log('after post');
                                // console.log(resp);
                                this.loading = false;
                                this.can_be_uploaded = false;
                                alert('Your Application Submitted Successfully.');
                            });
                        });
                    });
                    alert("Please stand by until your application is processed. Don't close this tab.");

                    this.applicationForm.reset();
                    this.onSubmitted = false;
                    this.file = null;
                    this.filename = '';
                },
                (error) => {
                    alert('There was an error submitting your application');
                },
            );
        } else {

        }
    };


    handleSuccess(event: any) {
        //console.log(event);
        this.captchaSuccess = true;
    }

    onFileSelector(e: Event | any) {
        this.filename = '';
        let select = e.target.files;
        if (select.length == 1) {
            if (select[0].size < 3000001) {
                if (select[0]?.name.includes('.pdf') && select[0].type.includes('pdf')) {
                    this.file = e.target.files[0];
                    this.filename +=
                        '<p class="text-strong">' +
                        select[0]?.name +
                        '</p><span class="text-muted">(' +
                        Math.floor(select[0].size / 10000) / 100 +
                        'MB)</span>';
                    this.can_be_uploaded = true;
                } else {
                    this.file = null;
                    this.filename = '';
                    this.can_be_uploaded = false;
                    alert('Only PDF File Format is Accepted');
                }
            } else {
                alert('Document Size should be below 3Mb');
                this.file = null;
                this.filename = '';
                this.can_be_uploaded = false;
            }
        } else {
            alert('You can upload only one file.');
        }
    }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        this.input = this.activatedRoute.snapshot.paramMap.get('id') || '';
        // console.log(this.input);
        this.loading = true;
        this.can_be_uploaded = false;
        let queried_obj: any;
        this.apollo
            .watchQuery<any>({
                query: POSITION_IDS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            // .pipe(map(data => {
            //     console.log(data.data.openPositions);
            //     let positions = data.data.openPositions;
            //     this.opening_positions_urls = positions.map((id) => id.open_position_url);
            //     if (!this.opening_positions_urls.includes(this.input)) {
            //         this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
            //         return;
            //     }
            //     queried_obj = data.data.openPositions.find(obj => obj.open_position_url == this.input);
            //     console.log(queried_obj);
            //     this.input_id = queried_obj.id;
            //     console.log(this.input_id);
            // }))

            // TO TRY TO AWAIT ONE WATCHQUERY TO USE VALUECHANGES VALUES IN THE SECOND QUERY - no need

            .subscribe(({ data }) => {
                // console.log(data);
                this.opening_positions_urls = data.openPositions.data.map((id: any) => id.attributes.Open_position_url);
                // console.log(this.opening_positions_urls);

                if (!this.opening_positions_urls.includes(this.input)) {
                    this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
                    return;
                }
                if (
                    this.input == 'backend-developer' ||
                    this.input == 'embedded-sw-developer' ||
                    this.input == 'frontend-developer' ||
                    this.input == 'sales-executive'
                ) {
                    if (this.fetchMetaService.getData() == false) {
                        let metatags = [];
                        this.apollo
                            .watchQuery({
                                query: META_QUERY,
                            })
                            .valueChanges
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((result) => {
                                let meta = [];
                                meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                                    ...metaTag,
                                }));
                                for (let i = 0; i < meta.length; i++) {
                                    metatags[meta[i].attributes.URL_ID] = meta[i];
                                }
                                // console.log("Meta Tags : ", this.metatags);
                                this.fetchMetaService.storeData(metatags);
                                let metaTagsData = this.fetchMetaService.getData();
                                // console.log("Meta Tags ", metaTagsData);
                                this.titleService.setTitle(metaTagsData[this.input].attributes.title);
                                this.metaService.updateTag({
                                    name: 'description',
                                    content: metaTagsData[this.input].attributes.description,
                                });
                                this.metaService.updateTag({
                                    name: 'keywords',
                                    content: metaTagsData[this.input].attributes.keywords,
                                });
                            });
                    } else {
                        // console.log("Data Alreday Fetched");
                        const data = this.fetchMetaService.getData();
                        this.titleService.setTitle(data[this.input].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content: data[this.input].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: data[this.input].attributes.keywords,
                        });
                    }
                } else {
                    this.titleService.setTitle('Cosgrid Networks');
                    this.metaService.updateTag({ name: 'description', content: '' });
                    this.metaService.updateTag({ name: 'keywords', content: '' });
                }
                queried_obj = data.openPositions.data.find((obj: any) => obj.attributes.Open_position_url == this.input);
                // console.log(queried_obj);
                this.input_id = queried_obj.id;
                // console.log(this.input_id);
                // console.log(this.opening_positions_urls);
                this.loading = false;
            });

        this.apollo
            .watchQuery<any>({
                query: POSITION_DETAILS_QUERY,
                variables: { open_position_url: this.input },
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ data }) => {
                // loading = false;
                // console.log(data);
                this.opening_positions_details = data['openPositions']['data'][0]['attributes'];
                // console.log('opening_positions_details ', this.opening_positions_details.Details.responsibilities);
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
