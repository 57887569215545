import * as aboutusModel from '../../../core/models/about.model';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { NguCarouselConfig } from '@ngu/carousel';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    bannerBackground: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/aboutus-background.jpg'
    whoWeAreBackground: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/who-we-are.jpg'
    TeamBackground: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/team.jpg'
    visionImage: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/vision.png'
    missionImage: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/mission.png'
    productApproachImage: string = 'https://vcdn.cosgrid.com/website/assets-new/aboutus/product-approach.png'

    ourValues = [
        {
            imgUrl: "https://vcdn.cosgrid.com/website/assets-new/aboutus/security.png",
            title: "Security",
            description: `Security is paramount at
                            COSGrid, reflected in our
                            focus on secure access,
                            data protection, and threat
                            detection solutions.
                            `
        },
        {
            imgUrl: "https://vcdn.cosgrid.com/website/assets-new/aboutus/innovation.png",
            title: "Innovation",
            description: `Patented SD-WAN and
                            focus on AI/ML security
                            solutions showcase our
                            commitment to innovation
                            in the cybersecurity space.
                            `
        },
        {
            imgUrl: "https://vcdn.cosgrid.com/website/assets-new/aboutus/simplicity.png",
            title: "Simplicity",
            description: `User-friendly platform and
                            focus on easy implementation
                            suggest that COSGrid prioritizes
                            making our solutions accessible
                            and straightforward to use.
                            `
        },
        {
            imgUrl: "https://vcdn.cosgrid.com/website/assets-new/aboutus/partnership.png",
            title: "Partnership",
            description: `Collaboration with industry
                            peers and government
                            agencies for threat intelligence
                            indicates a value of working
                            together to enhance overall
                            cybersecurity.
                            `
        }
    ]


    carouselTile: NguCarouselConfig = {
        grid: { xs: 2, sm: 2, md: 4, lg: 4, all: 0 },
        slide: 1,
        speed: 1500,
        point: {
            visible: true,
        },
        load: 4,
        velocity: 0,
        interval: {
            timing: 2000,
        },
        loop: true,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    associateWith: any[] = [
        {
            // title: 'Department of Telecommunications',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/Department of Telecommunications.png',
            alt: 'Department of Telecommunications',
            class: 'w-70',
        },
        {
            // title: 'TCOE',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/tcoe.png',
            alt: 'tcoe',
            class: 'w-40',
        },
        {
            // title: 'TEPC',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/tepc.png',
            alt: 'tepc',
            class: 'w-20',
        },
        {
            // title: 'iit',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/iit.png',
            alt: 'iit',
            class: 'w-80',
        },
        {
            // title: 'Microsoft',
            // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Microsoft.webp',
            alt: 'Microsoft',
            class: 'w-50',
        },
        {
            // title: 'Intel',
            // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/intelImage.png',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/intel.webp',
            alt: 'intel',
            class: 'w-40',
        },
        {
            // title: 'Google Cloud',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Google-cloud.png',
            alt: 'Google-cloud',
            class: 'w-70',
        },
        {
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/voice.webp',
            alt: 'voice',
            class: 'w-40',
        },
        {
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/n-coe-logo.webp',
            alt: 'n-coe-logo',
            class: 'w-40',
        },
    ];

    banner = {
        title: '',
        imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/aboutus-1.png',
        text: ['Company Overview'],
    };

    aboutCosGrid = [
        {
            title: '<span class="clr-blue-dark fw-bold">Zero Trust Access</span> with Ubiquitous & <span class="clr-blue-dark fw-bold">Reliable Connectivity</span>',
            image: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Holistic Security</span> & Advanced <span class="clr-blue-dark fw-bold">Threat Protection</span>',
            image: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Converged%20Security%20and%20Orchestration.webp',
        },
        {
            title: 'Deep <span class="clr-blue-dark fw-bold">Network Visibility</span> & Seamless <span class="clr-blue-dark fw-bold">Security Orchestration</span>',
            image: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
        },
    ];

    intro = 'Securely and reliably connects enterprise offices, work from home users,<br/>Cloud and Industrial Things.';

    aboutUs = {
        title: 'About Us',
        text: 'COSGrid Networks is a provider of enterprise and carrier-grade networking, network security and orchestration solutions. COSGrid Networks, a secure SD-WAN & SASE company founded in 2016 by IIM/IIT alumni, delivers superior WAN connectivity to Enterprise branch offices, remote working employees, IoT, and Cloud. COSGrid has been associated with many leading institutions such as DSCI’s National Centre of Excellence (NCoE) for Cybersecurity, SIIC IIT-Kanpur, TCoE, Dept of Telecom (DoT), Govt of India, Intel and Microsoft.COSGrid combines in-depth research and deep domain expertise in Networking & Cybersecurity to develop products and solutions that change how businesses around the world securely connect and operate over their digital backbone. We are the winners and one of the finalists of Cyber Security Grand Challenge by the DSCI & Ministry of Electronics and Information Technology, India, and the winner of TANSEED 2.0 by TANSIM. We have been granted an Indian patent in SD-WAN.COSGrid has a successful track record in delivering SD-WAN solutions across various verticals including Financial Services, Manufacturing, Telecom, Healthcare, Retail, IT, and Media.',
    };

    introItems = [
        {
            title: 'Secure & Reliable<br>connectivity',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
            width: '32%',
            height: '40%',
            alt: 'Zero Trust and Zero Glitch Network Access',
        },
        {
            title: 'Holistic security<br>Advanced threat protection',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Converged%20Security%20and%20Orchestration.webp',
            width: '40%',
            height: '50%',
            alt: 'Enhanced UX and Easy NetSecOps',
        },
        {
            title: 'Visibility and<br>Digital experience',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
            width: '40%',
            height: '50%',
            alt: 'Enhanced UX and Easy NetSecOps',
        },
    ];

    missionDesc: aboutusModel.IAboutUSDetails = {
        title: 'Mission',
        imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/aboutus-2.svg',
        text: [
            // 'To deliver secure network access, enable high performance and agile communications',
            // 'COSGrid stands for delivering Class Of Services(COS) through Grids.',
            // 'Class defined in terms of application driven network experience, reliability, security and scalability',
            'To create a leading smart and secure network access, data management, protection, and visibility platform that people and organizations can easily tap on & bank on',
        ],
    };

    leadershipDesc: aboutusModel.IAboutUSDetails = {
        title: 'Team',
        text: [
            'The leadership team at COSGrid has many decades of experience in the networking and network security technologies developing and launching global products. They have hands-on experience in running in some of the largest IP/MPLS networks and managed security services. In addition, with COSGrid’s patented innovations in SD-WAN & SASE and armed with recent capabilities such as cloud-native security orchestration with AI/ML, COSGrid is transforming the SD-WAN and SASE for enterprises.',
        ],
    };

    whyDoWeDoItDesc: aboutusModel.IAboutUSDetails = {
        title: 'Why do we do it?',
        imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/aboutus-why.svg',
        blog: [
            'Digital transformation plan is fine. But can it be simple to execute, time bound, deliver the desired RoI.',
            'Digital transformation depends on a secure and super reliable WAN. a.k.a real SD-WAN',
        ],
        text: [
            'As more and more enterprises embark on digital transformation including increased adoption of Cloud and IoT, the legacy Enterprise WAN architectures are not able to cope with the needs. In addition, given the pace of business operations and consequent changes required by IT and network to support ever-changing requirements makes the legacy WAN architectures a big obstacle.',
            'When we looked at the Enterprise networking landscape esp in WAN, the products available were too complex to use, took time to deploy, and were difficult to manage.',
            "We figured out the key customer's needs, technology, architecture, and the kind of company that can build such a platform. That’s when and why we set up COSGrid Networks.",
        ],
    };

    ourApproch: aboutusModel.IAboutUSDetails = {
        title: 'Our Approach',
        imgURL: 'https://vcdn.cosgrid.com/website/assets-new/aboutus/aboutus-approch.png',
        blocks: {
            start: 'We realized the need for new approaches that combines',
            points: [
                'Resilient and superior WAN for improved user application experience',
                'Rapid, plug & play WAN deployments and automated policy provisioning',
                'Integrated Security and bandwidth management',
                'Super-easy management of network services',
            ],
            end: "that is enabled by a powerful platform that makes a difference to customer's operations.",
        },
    };

    constructor(
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
        private canonicalService: CanonicalService,
        @Inject(PLATFORM_ID) protected platformId: Object
    ) { }

    screenwidth = 0;
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        if (isPlatformBrowser(this.platformId)) {
            this.screenwidth = window.innerWidth;
        }
    }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (isPlatformBrowser(this.platformId)) {
            this.screenwidth = window.innerWidth;
        }
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                        ...metaTag,
                    }));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log('Meta Tags ', metaTagsData);
                    this.titleService.setTitle(metaTagsData['aboutus'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData['aboutus'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['aboutus'].attributes.keywords,
                    });
                });
        } else {
            // console.log('Data Alreday Fetched');
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['aboutus'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data['aboutus'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['aboutus'].attributes.keywords,
            });
        }

        this.titleService.setTitle('COSGrid Networks | Secure SD-WAN & SASE Solutions | Company Overview');
        this.metaService.updateTag({ name: 'description', content: 'COSGrid Networks: Secure SD-WAN, SASE, and network security solutions provider. Explore our mission, transformative approach and vision for a better secured digital infrastructure.' });
        this.metaService.updateTag({ name: 'keywords', content: 'COSGrid Networks, secure SD-WAN, ZTNA, NDR, SASE solutions, network security, digital infrastructure, leadership team, partnerships, industry recognitions, smart network access, data management, protection, visibility, digital transformation, cloud adoption, IoT, WAN architectures, enterprise networking' });
    }

    row1: any[] = [
        {
            title: 'Department of Telecommunications',
            imgURL: 'Department of Telecommunications',
        },
        {
            title: 'TCOE',
            imgURL: 'tcoe',
            maxHeight: 100,
        },
        {
            title: 'TEPC',
            imgURL: 'tepc',
            maxHeight: 80,
        },
        {
            title: 'iit',
            imgURL: 'iit',
            maxHeight: 250,
        },
    ];
    row2: any[] = [
        {
            title: 'DSCI Logo',
            imgURL: 'dsci-logo',
        },
        {
            title: 'N COE',
            imgURL: 'n-coe-logo',
            maxHeight: 150,
        },
        {
            title: 'voice',
            imgURL: 'voice',
        },
        {
            title: 'Startup TN',
            imgURL: 'startup tn',
            maxHeight: 150,
        },
    ];

    inputs: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1100,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 2500,
        },
        loop: true,
        touch: true,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
    achievements_dialog: any = [
        {
            id: 1,
            imgURL: '1- ASEAN',
            content:
                'One of the Select Startups by DST,India representing India Delegation at India ASEAN Startup Summit 2023',
        },
        {
            id: 2,
            imgURL: '3 (1)',
            content:
                'Appreciated by Hon’ble Minister of Communications Shri.Ashwini Vaishnaw for our 5G SD-WAN at Odisha 5G Services Inauguration',
        },
        {
            id: 3,
            imgURL: 'IMG20231028160638',
            content: 'Launched our Z3 SASE prelude at India mobile congress 2023 and got appreciation by DOT, GOI.',
        },
        {
            id: 4,
            imgURL: '1677791480521 (2)',
            content:
                'Part of the biggest telecom industry event Mobile World Congress 2023 at India pavilion start-ups.',
        },
    ];

    datahalflength = [0, 2];

    openCompanyProfile() {
        if (isPlatformBrowser(this.platformId))
            window.open('https://vcdn.cosgrid.com/website/strapi/COS_Grid_Networks_Company_profile_f965ed6465.pdf');
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
