import * as partnersModel from '../../../core/models/partners.model';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../config/route.config';
import { CanonicalService } from '../../../queries/canonical/canonical.service';


export interface IndustryPartnerItem {
    title?: string;
    class?: string;
    imgURL: string;
}

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit, OnDestroy {
    CONTACTUS_LINK = `/${RouteConstants.CONTACTS}/.`;

    backgroundBanner = 'https://vcdn.cosgrid.com/website/assets-new/partner/partners-banner-background.jpg'

    private destroy$ = new Subject<void>();
    partnersProgram: partnersModel.IPartnersDetails = {
        title: 'COSGrid Networks Partner Program',
        text: [
            'The estimated <span class="clr-blue-dark fw-bold">Global Cybersecurity</span> TAM could potentially reach a staggering <span class="clr-blue-dark fw-bold">$1.5 to $2 trillion</span>, which is approximately 10 times larger than the vended market (McKinsey & Company). Additionally, <span class="clr-blue-dark fw-bold">Gartner</span> has predicted that the <span class="clr-blue-dark fw-bold">SASE</span> market will experience significant growth, reaching <span class="clr-blue-dark fw-bold">$25 billion with a CAGR of 29%</span> by the year 2027. The increase in remote work and cloud adoption has significantly expanded the attack surface, while the integration of multiple products has resulted in gaps and a poor return on investment (ROI).',
            'COSGrid Networks is fully committed to the vision of <span class="clr-blue-dark fw-bold">Smart SASE NetSecOps</span>. We aim to achieve this vision through the utilization of our patented technologies and innovative network controller architecture. To accomplish our goals, COSGrid will primarily collaborate with our valued partners, including <span class="clr-blue-dark fw-bold">VAR/Reseller , System integrators(SI), VAD/Distributors , MSSP & MSPs , IT Consultants  and Technology partners.</span>',
        ],
    };

    whyCOSGrid: partnersModel.IPartnersDetails = {
        title: 'Why COSGrid?',
        imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/Choose%20COSGrid.svg',
        text: [
            'Our partners can <span class="clr-blue-dark fw-bold">easily and quickly implement SD-WAN & SASE</span> solutions for their customers with our <span class="clr-blue-dark fw-bold">integrated solutions</span> and <span class="clr-blue-dark fw-bold">seamless deployment</span> bundles. COSGrid\'s Partner Program offers <span class="clr-blue-dark fw-bold">comprehensive support throughout the entire lifecycle</span>, from pre-sales to deployment to operations tech support. This support includes prospect identification, business case preparation, marketing and tech collaterals, co-branded campaigns, tech consulting, and WAN migration.',
        ],
    };

    becomePartner: partnersModel.IPartnersDetails = {
        title: 'Become a Partner',
        text: [
            'If we sound like the right partner with the right product or market, then we’d love to chat with you about possible partnerships. If you’re interested, please drop a mail to us at <a href="mailto:info@cosgrid.com"> info@cosgrid.com</a>',
            'Alternatively, you can register here',
        ],
    };
    ourPartners: any = [
        {
            id: 1,
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/Coral-Logo-700x464-1.png',
            height: '',
            class: 'w-60',
            alt: 'Coral',
        },
        {
            id: 2,
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/signaltron.png',
            height: '',
            class: 'w-60',
            alt: 'Signaltron',
        },
        {
            id: 3,
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/msolsuk_logo.jpg',
            height: '',
            class: 'w-60',
            alt: 'MSOLS UK',
        },
        {
            id: 4,
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/V secure logo.png',
            height: '',
            class: 'w-40',
            alt: 'V Secure',
        }
    ]

    industry_partners: IndustryPartnerItem[] = [
        {
            title: 'Microsoft',
            // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/Microsoft.webp',
            class: 'w-60'
        },
        {
            title: 'Intel',
            // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/intelImage.png',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/intel.webp',
            class: 'w-40'
        },
        {
            title: 'Google Cloud',
            imgURL: 'https://vcdn.cosgrid.com/website/assets-new/partner/Google-cloud.png',
            class: 'w-70'
        }

    ];

    constructor(private canonicalService: CanonicalService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        // this.klentyService.load(); //loads the klenty script
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['partners'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['partners'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['partners'].attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['partners'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['partners'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['partners'].attributes.keywords
            });

        }

        // this.titleService.setTitle('COSGrid Networks | Partner Program | Join Our Network of Success');
        // this.metaService.updateTag({ name: 'description', content: "Join COSGrid Networks' Partner Program and expand your business with secure SD-WAN solutions." });
        // this.metaService.updateTag({ name: 'keywords', content: 'COSGrid Networks, Partner Program, SD-WAN solutions, VAR/Reseller, system integrators, MSPs, IT Consultants, technology partners, integrated solutions, seamless deployment, support, co-branded marketing campaigns, WAN migration, partnership opportunities' });
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
