// import { ImagePointsModule } from './core/modules/image-points.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { NguCarouselOutlet } from '@ngu/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TransferState, makeStateKey } from '@angular/core';

// import { ContactsComponent } from './pages/contacts/contacts.component';
// import { AboutusComponent } from './pages/aboutus/aboutus.component';

// import { PartnersComponent } from './pages/partners/partners.component';
import { MatTabsModule } from '@angular/material/tabs';
// import { SitemapComponent } from './pages/sitemap/sitemap.component';
// import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
// import { ProductsComponentsComponent } from './pages/productsComponents/productsComponents.component';
import { Router, RouterModule } from '@angular/router';

// import { NavbarComponent } from './shared/navbar/navbar.component';
// import { FooterComponent } from './shared/footer/footer.component';

// import { AssociationComponent } from './pages/association/association.component';
// import { CarouselComponent } from './shared/carousel/carousel.component';
// import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
// import { FaqsComponent } from './pages/faqs/faqs.component';
// import { GalleryComponent } from './pages/gallery/gallery.component';
import { ToastrModule } from 'ngx-toastr';
// import { SdWanComponent } from './pages/sd-wan/sd-wan.component';
// import { CookieConsentComponent } from 'src/app/shared/cookie-consent/cookie-consent.component';
// import { AwardsComponent } from './pages/news/awards/awards.component';
// import { EventsComponent } from './pages/news/events/events.component';
import { Location } from '@angular/common';
import { KlentyService } from './klenty.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { DatePipe } from '@angular/common';
// import { MobilefooterComponent } from './shared/mobilefooter/mobilefooter.component';
// import { ContactpageComponent } from './pages/contactpage/contactpage.component';
// import { CancelationpageComponent } from './pages/cancelationpage/cancelationpage.component';
// import { MobileNavbarComponent } from './shared/mobilenav/mobilenav.component';

// import { SaseComponent } from './pages/sase/sase.component';
// import { ConfettiComponent } from './pages/confetti/confetti.component';
// import { ImageListModule } from './core/modules/image-list-module';
// import { TableListModule } from './core/modules/table-list-module';
// import { SliderListModule } from './core/modules/slider-list.module';
// import { ZtnaComponent } from './pages/ztna/ztna.component';
// import { FirewallComponent } from './pages/firewall/firewall.component';
// import { ResourceListModule } from './core/modules/resource-list.module';
// import { FeaturesListModule } from './core/modules/features-list.module';
// import { ProblemListModule } from './core/modules/problem-list.module';
// import { PressReleaseComponent } from './pages/press-release/press-release.component';
// import { ProductsComponent } from './pages/products/products.component';





// new webiste
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsModule } from './pages/products/products.module';
import { SharedModule } from './shared/shared.module';
import { ResourcesModule } from './pages/resources/resources.module';
import { IndustriesComponent } from './pages/industries/industries.component';
import { SitemapComponent } from './pages/others/sitemap/sitemap.component';
import { PrivacyPolicyComponent } from './pages/others/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/others/terms-of-use/terms-of-use.component';
import { ContactpageComponent } from './pages/others/contactpage/contactpage.component';
import { ProductVPNComponent } from './pages/others/productvpn/productvpn.component';
import { MatIconModule } from '@angular/material/icon'

// import { UsecasesComponent } from './pages/others/usecases/usecases.component';
import * as Sentry from "@sentry/angular-ivy";


const EXAMPLE_KEY = makeStateKey<any>('exampleKey');

@NgModule({
  declarations: [
    // new website
    AppComponent,
    IndustriesComponent,
    SitemapComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    ContactpageComponent,
    ProductVPNComponent,

  ],
  imports: [
    NguCarouselOutlet,

    CommonModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    TransferHttpCacheModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    RouterModule,
    AppRoutingModule,

    // new website
    ProductsModule,
    SharedModule,
    ResourcesModule,
    MatIconModule
  ],
  providers: [KlentyService, DatePipe, {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }],
  bootstrap: [AppComponent],
})
export class AppModule { }

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//     //console.log(url);
//     //console.log(containshashval + ' ' + url);

//     if (url.endsWith('partners') || url.endsWith('sd-wanusecases') || url.endsWith('sd-wan') || url.includes('#')) {
//         return url;
//     }

//     if (url.endsWith('/')) {
//         url = url;
//     } else {
//         url = url + '/';
//     }
//     const queryString$ = url.match(/([^?]*)?(.*)/);
//     if (queryString$[2].length > 0) {
//         return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//     }
//     return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };
