<section style="min-height: 85vh;">
    <div class="my-5 pt-3" [ngStyle]="{'background': 'url(' + bannerBackground + ') no-repeat center 20% /cover'}"
        style=" height: 65vh; padding-right: 10%;">
        <div class="d-flex justify-content-end align-items-center h-100">
            <div class="">
                <p class="fw-bold text-white display-3">About Us</p>
            </div>
        </div>
    </div>
    <div>
        <p class="fw-bold clr-blue-dark h1 text-center">Cyber Resilency simplified with Smart SASE NetSecOps</p>
    </div>
</section>



<section class="px-md-5 px-2 my-5">
    <h1 class="fw-bold mb-3 h2 clr-blue-dark">Who we are</h1>
    <div class="p-5" [ngStyle]="{'background': 'url(' + whoWeAreBackground + ') no-repeat center 20% /cover'}">
        <div class="row">
            <div class="col-12 col-md-6">
                <p class="fs-5 text-white text-justify">
                    COSGrid Networks is a leading
                    <span class="fw-bold">networking and cybersecurity products</span> company that delivers
                    secure
                    access and
                    <span class="fw-bold">protection to users, data, applications, and workloads</span> across
                    offices,
                    clouds, remote sites, and WFH users anywhere. COSGrid is one of the few unified SASE companies
                    globally
                    that enables <span class="fw-bold">‘Cyber-resilience Simplified’</span> for organizations
                    with
                    <span class="fw-bold">advanced threat detection, zero trust architecture
                        capabilities</span>, and
                    <span class="fw-bold">patented adaptive SD-WAN</span>-based traffic steering to deliver
                    seamless
                    connectivity across WAN links, including <span class="fw-bold">4G/5G</span>. Our advanced
                    solution
                    not only enhances the overall security posture but also minimizes the risk of data breaches and
                    ensures
                    the confidentiality, integrity, and availability of critical information. With our
                    <span class="fw-bold">AI/Big data ML-based Cybersecurity solutions</span> , organizations
                    can stay
                    one step ahead of Attackers and safeguard their digital assets effectively.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="px-md-5 px-2 my-5">
    <h1 class="fw-bold mb-3 h2 clr-blue-dark">Our Approach</h1>
    <div class="row pt-2">
        <div class="col-md-4 col-12 d-flex flex-column justify-content-center align-items-center"
            *ngFor="let about of aboutCosGrid">
            <img [src]="about.image" class="h-40 mb-4" alt="about image" loading="lazy" />
            <p class=" text-center px-lg-5 fs-6" [innerHTML]="about.title"></p>
        </div>
    </div>
</section>

<section>
    <div class="text-center">
        <img class="w-70" [src]="productApproachImage" alt="product approach image">
    </div>
</section>

<section class="px-md-5 px-2 my-5">
    <h1 class="fw-bold mb-3 h2 clr-blue-dark">Our Vision & Mission</h1>
    <div class="mx-auto">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                <div style="max-width: 20rem;">
                    <div class="d-flex justify-content-center align-items-center">
                        <img style="width: 6rem;" [src]="visionImage" alt="vision">
                    </div>
                    <div>
                        <p>
                            To create a <span class="clr-blue-dark fw-bold">better secured digital infrastructure</span>
                            that
                            enables people
                            and organizations safely go about their digital lives
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                <div style="max-width: 20rem;" class="d-flex flex-column justify-content-center align-items-center">
                    <div class="d-flex justify-content-center align-items-center">
                        <img style="width: 6rem;" [src]="missionImage" alt="mission">
                    </div>
                    <div>
                        <p>
                            To create a
                            <span class="clr-blue-dark fw-bold">leading smart and secure network access, data
                                management,
                                protection, and visibility</span>
                            platform that people and organizations can easily tap on & bank on
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="px-md-5 px-2 my-5">
    <h1 class="fw-bold mb-5 h2 clr-blue-dark">Our Values</h1>
    <div class="mt-5 mx-auto">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items center"
                *ngFor="let value of ourValues;let i = index;">
                <div style="max-width: 18rem;">
                    <div>
                        <img style="width: 6rem;" [src]="value.imgUrl" alt="our values">
                    </div>
                    <div>
                        <p class="fw-bold h3" [innerHTML]="value.title"></p>
                    </div>
                    <div>
                        <p class="text-justify" [innerHTML]="value.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="px-md-5 my-5">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Associations and Industry partners</h1>
        <ngu-carousel [inputs]="carouselTile" [dataSource]="associateWith">
            <div class="item d-flex justify-content-center align-items-center" *nguCarouselDef="let item; let i = index"
                style="border: none; box-shadow: none">
                <img [src]="item.imgURL" [alt]="item?.alt" [class]="item.class" loading="lazy" />
            </div>
        </ngu-carousel>
    </div>
</section>


<section class="my-5">
    <div class="px-md-5 px-2">
        <h1 class="fw-bold h2 clr-blue-dark">Team</h1>
    </div>
    <div class="p-5 mt-5" [ngStyle]="{'background': 'url(' + TeamBackground + ') no-repeat center 20% /cover'}">
        <div class="row">
            <div class="col-12 col-md-6"></div>
            <div class="col-12 col-md-6">
                <p class="fs-5 text-justify">
                    The leadership team at COSGrid has <span class="">many decades of experience</span>
                    in the
                    networking and network security technologies developing and launching global products. They have
                    handson
                    experience in running in some of the largest IP/MPLS networks and managed security services. In
                    addition, with <span class="">COSGrid’s patented innovations</span> in SD-WAN &
                    SASE and
                    armed with recent capabilities such as cloud-native security orchestration with AI/ML, COSGrid is
                    transforming the SD-WAN and SASE for enterprises.
                </p>
            </div>
        </div>
    </div>
</section>



<!-- <section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-md-6 mt-md-4 d-flex flex-column justify-content-center">
            <p class="fw-bold h2 text-center text-md-left">Cyber Resilency simplified with Smart SASE NetSecOps</p>
            <img src="https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg" height="70px" class="pt-3 mb-3"  loading="lazy"/>
            <p class="me-lg-2 fs-6">
                <span class="clr-blue-dark fw-bold">COS </span>- Class of Services for Data through Secure Systems and
                Networks
                with reliable and agile communications
            </p>
            <span class="fs-6 mb-1">Delivered through </span>
            <p class="fs-6">
                <span class="clr-blue-dark fw-bold">Grid </span>Approach - Modular, Organized, Responsive, Scalable and
                Easy to
                collaborate
            </p>
            <button type="button" class="btn btn-primary button rounded-5 hover-popup fs-6 fw-semibold mb-5 mb-md-0"
                (click)="openCompanyProfile()">
                Download company profile
            </button>
        </div>
        <div class="col-12 col-md-6 mt-md-4 d-flex justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets/images1/about%20us/about%20us%203d.png" class="w-80" loading="lazy"/>
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="d-flex flex-sm-row flex-column">
        <div class="col-md-4 col-12 d-flex flex-column justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets/images1/about%20us/company%20overview.png" class="w-80" loading="lazy"/>
        </div>
        <div class="col-md-8 col-12">
            <p class="fw-bold clr-blue-dark h2">Company Overview</p>
            <p class="pt-3 text-justify fs-6">
                COSGrid Networks is a leading
                <span class="clr-blue-dark fw-bold">networking and cybersecurity products</span> company that delivers
                secure
                access and
                <span class="clr-blue-dark fw-bold">protection to users, data, applications, and workloads</span> across
                offices,
                clouds, remote sites, and WFH users anywhere. COSGrid is one of the few unified SASE companies globally
                that enables <span class="clr-blue-dark fw-bold">‘Cyber-resilience Simplified’</span> for organizations
                with
                <span class="clr-blue-dark fw-bold">advanced threat detection, zero trust architecture
                    capabilities</span>, and
                <span class="clr-blue-dark fw-bold">patented adaptive SD-WAN</span>-based traffic steering to deliver
                seamless
                connectivity across WAN links, including <span class="clr-blue-dark fw-bold">4G/5G</span>. Our advanced
                solution
                not only enhances the overall security posture but also minimizes the risk of data breaches and ensures
                the confidentiality, integrity, and availability of critical information. With our
                <span class="clr-blue-dark fw-bold">AI/Big data ML-based Cybersecurity solutions</span> , organizations
                can stay
                one step ahead of Attackers and safeguard their digital assets effectively.
            </p>
            <div class="row pt-2">
                <div class="col-md-4 col-12 d-flex flex-column justify-content-center align-items-center"
                    *ngFor="let about of aboutCosGrid">
                    <img [src]="about.image" class="h-40 mb-4" loading="lazy"/>
                    <p class=" text-center px-lg-5 fs-6" [innerHTML]="about.title"></p>
                </div>
            </div>
            <br />
            <p>
                Product Areas :
                <span class="clr-blue-dark fw-bold">SD-WAN | SASE | ZTNA | NDR | NG Firewall | IoT Gateways | DEM
                </span>
            </p>
            <p>Architecture & Management : <span class="clr-blue-dark fw-bold">On-Premise | Cloud | Hybrid</span></p>
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="d-flex flex-sm-row flex-column row">
        <div class="col-md-8 col-12">
            <p class="fw-bold clr-blue-dark h2">Vision & Mission</p>
            <p class="fw-bold pt-3 h3">Vision</p>
            <a class="text-black fs-6">
                To create a <span class="clr-blue-dark fw-bold">better secured digital infrastructure</span> that
                enables people
                and organizations safely go about their digital lives
            </a>
            <p class="fw-bold pt-3 h3">Mission</p>
            <a class="fs-6 text-black">
                To create a
                <span class="clr-blue-dark fw-bold">leading smart and secure network access, data management,
                    protection, and visibility</span>
                platform that people and organizations can easily tap on & bank on
            </a>
        </div>
        <div class="col-md-4 col-12 d-flex flex-column justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets/images1/about us/vision (2).png" class="img-fluid"
                style="max-height: 200px" loading="lazy"/>
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="d-flex flex-sm-row flex-column row flex-column-reverse flex-md-row">
        <div class="col-sm-4 col-12 d-flex flex-column justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets/images1/about us/team1.png" class="img-fluid"
                style="max-height: 200px" loading="lazy"/>
        </div>
        <div class="col-sm-8 col-12">
            <p class="fw-bold clr-blue-dark h2">Team</p>
            <p class="pt-3 fs-6">
                The leadership team at COSGrid has <span class="clr-blue-dark fw-bold">many decades of experience</span>
                in the
                networking and network security technologies developing and launching global products. They have handson
                experience in running in some of the largest IP/MPLS networks and managed security services. In
                addition, with <span class="clr-blue-dark fw-bold">COSGrid’s patented innovations</span> in SD-WAN &
                SASE and
                armed with recent capabilities such as cloud-native security orchestration with AI/ML, COSGrid is
                transforming the SD-WAN and SASE for enterprises.
            </p>
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="d-flex flex-sm-row flex-column">
        <div class="col-md-8 col-12">
            <p class="fw-bold clr-blue-dark h2">Associations</p>
            <p class="fs-6">
                We are proud to be associated with leading institutions such as DSCI’s National Centre of Excellence
                (NCoE) for Cybersecurity, SIIC IIT-Kanpur,TCoE, Dept of Telecom (DoT), Govt of India, Intel, Microsoft
                and Google Cloud.
            </p>
            <div *ngIf="screenwidth >= 1250 || screenwidth <= 567">
                <div class="d-flex flex-wrap justify-content-between">
                    <img
                        [alt]="item?.title"
                        loading="lazy"
                        class="img-fluid"
                        style="object-fit: contain"
                        [style]="'max-width: ' + (item.hasOwnProperty('maxHeight') ? item.maxHeight : 150) + 'px'"
                        *ngFor="let item of row1"
                        [src]="'https://vcdn.cosgrid.com/website/assets/images1/associations/' + item.imgURL + '.png'"
                    />
                </div>
                <div class="d-flex flex-wrap justify-content-between">
                    <img
                        [alt]="item?.title"
                        class="img-fluid mt-4"
                        loading="lazy"
                        style="object-fit: contain"
                        [style]="'max-width: ' + (item.hasOwnProperty('maxHeight') ? item.maxHeight : 100) + 'px'"
                        *ngFor="let item of row2"
                        [src]="'https://vcdn.cosgrid.com/website/assets/images1/associations/' + item.imgURL + '.png'"
                    />
                </div>
            </div>
            <div *ngIf="screenwidth < 1250 && screenwidth > 567">
                <div *ngFor="let i of [0, 1, 2]" class="row">
                    <div
                        [ngClass]="{
                            'col-6': i == 1,
                            'col-4': i != 1
                        }"
                        class="d-flex flex-column justify-content-center align-items-center"
                        *ngFor="
                            let item of i == 0
                                ? [row1[0], row1[1], row1[2]]
                                : i == 1
                                ? [row1[3], row2[0]]
                                : [row2[1], row2[2], row2[3]]
                        "
                    >
                        <img
                        loading="lazy"
                            [alt]="item?.title"
                            class="img-fluid mt-4"
                            style="object-fit: contain"
                            [style]="'max-width: ' + (item.hasOwnProperty('maxHeight') ? item.maxHeight : 100) + 'px'"
                            [src]="
                                'https://vcdn.cosgrid.com/website/assets/images1/associations/' + item.imgURL + '.png'
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-12 d-md-flex flex-column justify-content-center align-items-center mt-3 d-none ">
            <img
                src="https://vcdn.cosgrid.com/website/assets/images1/about us/association.png"
                class="img-fluid"
                style="max-height: 200px"
                loading="lazy"
            />
        </div>
    </div>
</section> -->

<!-- achievements -->
<section class="px-md-5 my-5 px-2">
    <p class="fw-bold text-center mt-5 clr-blue-dark h2">Achievements</p>
    <div class="w-100 d-flex flex-row justify-content-center align-items-center">
        <div class="w-90">
            <div *ngIf="screenwidth >= 1000">
                <ngu-carousel #myCarousel [inputs]="inputs" [dataSource]="datahalflength">
                    <ngu-tile *nguCarouselDef="let item; let i = index">
                        <div class="d-flex flex-row justify-content-center align-items-center pt-2 carouselBox"
                            style="height: 220px">
                            <div class="pt-3 pb-5 d-flex flex-row px-3 justify-content-center align-items-center"
                                style="width: 45%" *ngFor="
                                    let item of [achievements_dialog[item], achievements_dialog[item + 1]];
                                    index as i
                                " [ngClass]="{
                                    'justify-content-end': i == 0,
                                    'justify-content-start': i == 1
                                }">
                                <img [src]="
                                        'https://vcdn.cosgrid.com/website/assets-new/aboutus/' +
                                        item.imgURL +
                                        '.jpg'
                                    " class="img-fluid" style="max-height: 120px" loading="lazy" alt="achievments" />
                                <a class="text-black fs-6 px-3">{{ item.content }}</a>
                            </div>
                        </div>
                    </ngu-tile>

                    <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="myCarousel.isFirst() ? 0.2 : 0.7"
                        style="z-index: 1">
                        <i class="fa fa-angle-left"></i>
                    </button>

                    <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast() ? 0.2 : 0.7">
                        <i class="fa fa-angle-right" style="user-select: auto"></i>
                    </button>
                </ngu-carousel>
            </div>
            <div *ngIf="screenwidth < 1000">
                <ngu-carousel #myCarousel [inputs]="inputs" [dataSource]="achievements_dialog">
                    <ngu-tile *nguCarouselDef="let item; let i = index">
                        <div class="d-flex flex-row justify-content-center align-items-center pt-2 carouselBox"
                            style="height: 220px">
                            <div class="pt-3 pb-5 row d-flex flex-row px-3 justify-content-center align-items-center"
                                style="width: 90%" [ngClass]="{
                                    'justify-content-end': i == 0,
                                    'justify-content-start': i == 1
                                }">
                                <div class="col-12 col-sm d-flex flex-column justify-content-center align-items-center">
                                    <img [src]="
                                            'https://vcdn.cosgrid.com/website/assets-new/aboutus/' +
                                            item.imgURL +
                                            '.jpg'
                                        " class="img-fluid" style="max-height: 120px" loading="lazy"
                                        alt="achievements in about us" />
                                </div>
                                <div class="col-12 col-sm">
                                    <a class="text-black fs-6 px-3">{{ item.content }}</a>
                                </div>
                            </div>
                        </div>
                    </ngu-tile>

                    <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="myCarousel.isFirst() ? 0.2 : 0.7"
                        style="z-index: 1">
                        <i class="fa fa-angle-left"></i>
                    </button>

                    <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast() ? 0.2 : 0.7">
                        <i class="fa fa-angle-right" style="user-select: auto"></i>
                    </button>
                </ngu-carousel>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="text-center">
        <button type="button" class="my-2 bg-clr-blue-dark bg-gradient px-4 py-2 border-0 text-white h5 rounded"
            (click)="openCompanyProfile()">
            Download company profile
        </button>
    </div>
</section>