<!-- <div style="height: 4vh"></div> -->


<!-- top -->
<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center mb-5">
            <h1 class="fw-bold mb-3 h2 text-center text-md-left">{{ main1?.intro?.product_line }}</h1>
            <img [src]="main1?.intro?.logoURL" class="w-70" alt="NetShield" loading="lazy" />
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center ">
            <img [src]="main1?.intro?.imgURL" alt="Banner" class="w-90 animate-bob" loading="lazy" />
        </div>
    </div>
</section>
<!-- about -->
<section class="px-md-5 my-5 px-2 about">
    <div class="row p-3">
        <div class="px-lg-5 col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h1 class="mb-3 fw-bold clr-white h2">Overview</h1>
            <p class="text-justify clr-white fs-6">{{ main1?.intro?.text }}</p>
            <div class="">
                <a href="https://vcdn.cosgrid.com/website/strapi/Brochure_Net_Shield_V3_314d4cd3b2.pdf" target="_blank"
                    class="btn btn-primary rounded-pill hover-popup align-center fw-bold">Download Datasheet</a>
            </div>
        </div>
        <div class="col-lg-6 ">
            <carousel-list [data]="main1?.overview" baseUrl="https://vcdn.cosgrid.com/website/assets-new/ndr/"
                [singleCol]="true"></carousel-list>
        </div>
    </div>
</section>

<!-- overview  -->
<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-lg-6 ps-lg-3">
            <p class="fw-bold mb-3 clr-blue-dark h3">Security of access for borderless networks</p>
            <div *ngFor="let item of main1?.ndrOverview" class="mb-3">
                <p class="mb-2 fw-bold fs-5">{{item.title}}</p>
                <div *ngFor="let point of item.points" class="d-flex mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="18px" height="18px"
                        class="mt-1"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#004d65"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                    <p [innerHTML]="point" class="m-0 fs-6"></p>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/COSGrid NDR-NetShield Overview.png"
                alt="NDR-NetShield" class="w-80" loading="lazy">
        </div>
    </div>
</section>

<!-- Comprehensive functions -->
<section class="px-md-5 my-5 px-2">
    <image-points image="https://vcdn.cosgrid.com/website/assets-new/ndr/6.webp" title="Comprehensive functions"
        subtitle="NetShield NDR is also able to consolidate multiple security components into a single superset platform for both detection and response. NDR offers a unified and comprehensive approach to network security by integrating various technologies such as:"
        [data]="main1?.diff" leftContentXLCol="6"></image-points>
</section>

<!-- Gaps in Effectiveness -->
<section class="px-md-5 my-5 px-2">
    <h1 class="text-center fw-bold clr-blue-dark h2">Gaps in Effectiveness</h1>
    <p class=" text-center fs-6">of network security that may affect your business</p>
    <div class="container">
        <div class="row">
            <div class="col-12 d-none">
                <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/22.webp" class="img-fluid" loading="lazy"
                    alt="Steps" />
            </div>
        </div>
        <div class="row d-flex justify-content-center align mr-md-2 ml-md-2 mr-1 ml-1">
            <div class="col-md-4 col-12 d-flex flex-column justify-content-center class1">
                <p class="text-center p-0 m-0 fw-bold fs-5 fw-bold pb-3">End Point Detection and Response</p>
                <div class="d-flex align-items-center p-2 m-0"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc">
                    <ul class="d-flex justify-content-center flex-column pt-1 px-2 pb-1 fs-6" style="list-style: none">
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Agents not installed for all
                            platforms/OS , such as IOT/OT and BYOD
                        </li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Attackers can evade or tamper
                            endpoint agent
                        </li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Difficult to map the attacker
                            movement
                        </li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Provides limited features and
                            threat detection
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center justify-content-center m-0 mt-2"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc; height: 65px">
                    <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/7.webp"
                        class="img-fluid align-self-center" loading="lazy" alt="End Point Detection" />
                </div>
            </div>
            <div class="col-md-4 col-12 d-flex flex-column justify-content-start class-2">
                <p class="text-center p-0 m-0 fs-5 fw-bold pb-3">Intrusion Detection System</p>
                <div class="d-flex align-items-center p-2 m-0"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc">
                    <ul class="d-flex justify-content-center flex-column pt-1 px-2 pb-1 fs-6" style="list-style: none">
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Signature based : cannot handle
                            zero-day attacks and sophisticated APTs
                        </li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Lack of device Identification &
                            Classification means lack of context & appropiate response
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center justify-content-center m-0 mt-2"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc; height: 65px">
                    <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/8.webp"
                        class="img-fluid align-self-center" loading="lazy" alt="Intrusion Detection System" />
                </div>
            </div>
            <div class="col-md-4 col-12 d-flex flex-column justify-content-center class-3">
                <p class="text-center p-0 m-0 fs-5 fw-bold pb-3">
                    Security Information and Event Managment
                </p>
                <div class="d-flex align-items-center p-2 m-0"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc">
                    <ul class="d-flex justify-content-center fs-6 flex-column pt-1 px-2 pb-1" style="list-style: none">
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span> Focus on aggregating and
                            correlating logs
                        </li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span>Rule based and less on
                            behavior-based anomaly detection
                        </li>
                        <li class="fs-6"><span class="font-weight-bold"> &#8227; </span>Context insensitive</li>
                        <li class="fs-6">
                            <span class="font-weight-bold"> &#8227; </span>Too Many FP's Mean Time to
                            detect(MTTD) is very high
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center justify-content-center m-0 mt-2"
                    style="background: #c9daf8cc; border: 1px solid #c9daf8cc; height: 65px">
                    <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/9.webp"
                        class="img-fluid align-self-center" loading="lazy" alt="Security Information " />
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex bg-clr-blue px-lg-5 justify-content-center align-items-center p-2 rounded">
        <img width="40px" height="40px" src="https://vcdn.cosgrid.com/website/assets-new/ndr/10.webp" loading="lazy"
            alt="Icon" />
        <div class="ms-2">
            <p *ngFor="let i of main1?.points" class="clr-white d-flex fs-6">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="16px"
                        height="16px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#fff"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                </span>
                <span>{{ i }}</span>
            </p>
        </div>

    </div>
</section>

<!-- ndr helps -->
<section class="px-md-5 my-5 px-2">
    <div>
        <h1 class="clr-blue-dark fw-bold text-center h2">How NetShield NDR helps?</h1>
        <p class="text-center mb-0 fs-5">From Signatures to intelligent behavioral detection and more…</p>
        <p class="text-center fs-5">To bridge the existing gaps in cybersecurity effectiveness, there is a pressing
            requirement for:</p>
        <div class="row mx-lg-5">
            <div class="col-12 col-lg-6" *ngFor="let item of main1?.ndr_helps">
                <div class="shadow p-lg-5 p-3 rounded hover-popup">
                    <p class="fw-bold clr-blue-dark mb-4 fs-5">{{item.title}}</p>
                    <div style="height: 16rem;">
                        <div *ngFor="let i of item.points" class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="18px" height="18px"
                                class="mt-1"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path fill="#004d65"
                                    d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                            </svg>
                            <p class="ms-2 fs-6">{{i}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- benefits -->
<section class="px-md-5 my-5 px-2">
    <image-points [data]="netshieldBenefits" subtitle="That helps your organisation worry less about network security"
        title="Benefits of NetShield NDR" image="https://vcdn.cosgrid.com/website/assets-new/ndr/23.webp"
        leftContentXLCol="6" colorStyle="2" imagePosition="1"></image-points>
</section>

<!-- usecases -->
<section class="px-md-5 my-5 px-2">
    <div>
        <h1 class="fw-bold text-center clr-blue-dark h2">Use Cases</h1>
        <p class=" text-center fs-5">From Signatures to intelligent behavioral detection and more…</p>
        <table-list [spaceBtnTitleNImage]="false" [data]="main1?.use_cases" [xlGridsInARow]="3"
            baseUrl="https://vcdn.cosgrid.com/website/assets-new/ndr/"
            threeCellsInRowClassName="threeCellsInRowChallenges" [totalRowCount]="2"></table-list>
    </div>

</section>

<!-- deployment options & contact us -->
<section class="px-md-5 my-5 px-2">
    <h1 class="fw-bold text-center clr-blue-dark h2">Deployment Options</h1>
    <p class="text-center fs-6">Without affecting the current network security system of your organisation</p>
    <div class="d-flex justify-content-center mt-3">
        <img src="https://vcdn.cosgrid.com/website/assets-new/ndr/deployment.png" alt="benefits" class="w-60"
            loading="lazy" />
    </div>
    <div style="background-color: #ededed !important; margin-top: 3em">
        <div id="intro" class="container-fluid">
            <div class="row pt-2 pb-4 d-flex flex-column justify-content-center align-items-center">
                <div class="col-md-6 col-12 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="text-center text-md-left fw-bold h2">Interested in this product?</h1>
                    <p class="fs-6">Click the button below & submit your details - we will call you back!</p>
                    <a class="btn fs-6" routerLink="/company/contacts"
                        style="background-color: #0078d7; color: white">Contact
                        Us</a>
                </div>
                <!-- <div class="col-md-6 col-12 d-flex justify-content-center align-items-center flex-column">
                        
                    </div> -->
            </div>
        </div>
    </div>
</section>